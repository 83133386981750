<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="isVideoEnabled ? 'white': 'error'"
        :class="isVideoEnabled ? 'primary--text' : 'white--text'"
        fab v-bind="attrs" v-on="on"
        @click="$emit('click')"
      >
        <v-icon v-text="isVideoEnabled ? 'videocam' : 'videocam_off'" />
      </v-btn>
    </template>
    <span v-text="isVideoEnabled ? $t('streaming.buttons.disableVideo') : $t('streaming.buttons.enableVideo')" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'SwitchVideoButton',
  props: {
    isVideoEnabled: { type: Boolean, required: true },
  },
}
</script>
